//Header Array
export const gnbData = [
  { title: "환경설정", link: "/Setting" },
  { title: "회원관리", link: "/Member" },
  { title: "호텔관리", link: "/Hotel" },
  { title: "여행관리", link: "/Trip" },
  { title: "예약/결제내역", link: "/Reserve" },
  { title: "피드관리", link: "/Feed" },
  { title: "상품관리", link: "/Product" },
  { title: "게시판관리", link: "/Board" },
];

//Left Sub Gnb Array
export const subSetting = [
  { title: "운영자 관리", link: "Setting/Manager", depth: "depth1" },

  { title: "디자인관리", link: "Setting/Design", depth: "depth1" },
  {
    title: "메인 슬라이드 관리",
    link: "Setting/Design/MainSlide",
    depth: "depth2",
  },
  {
    title: "모바일 슬라이드 관리",
    link: "Setting/Design/MobileSlide",
    depth: "depth2",
  },
  {
    title: "평점 우수 호텔 관리",
    link: "Setting/Design/ExcellentRating",
    depth: "depth2",
  },
  {
    title: "MD추천해외호텔 관리",
    link: "Setting/Design/OverseasHotel",
    depth: "depth2",
  },
  {
    title: "이달의 추천여행",
    link: "Setting/Design/RecommendedHotel",
    depth: "depth2",
  },
  {
    title: "진행중 이벤트 관리",
    link: "Setting/Design/OnGoingEvent",
    depth: "depth2",
  },
  { title: "팝업 관리", link: "Setting/Design/Popup", depth: "depth2 last" },

  { title: "PUSH관리", link: "Setting/Push", depth: "depth1" },
  { title: "자동 PUSH 관리", link: "Setting/Push/Auto", depth: "depth2" },
  { title: "수동 PUSH 관리", link: "Setting/Push/Manual", depth: "depth2" },
  { title: "PUSH 발송내역", link: "Setting/Push/List", depth: "depth2 last" },

  { title: "숙박교환권 관리", link: "Setting/Coupon", depth: "depth1" },
  { title: "숙박교환권 내역", link: "Setting/Coupon/List", depth: "depth2" },
  {
    title: "숙박교환권 발급",
    link: "Setting/Coupon/Issue",
    depth: "depth2",
  },
  {
    title: "숙박교환권 발급내역",
    link: "Setting/Coupon/IssueList",
    depth: "depth2 last",
  },

  { title: "약관 관리", link: "Setting/Policy", depth: "depth1" },
  { title: "이용약관", link: "Setting/Policy/Terms", depth: "depth2" },
  {
    title: "개인정보처리방침",
    link: "Setting/Policy/Privacy",
    depth: "depth2",
  },
  { title: "광고성정보수신", link: "Setting/Policy/Ad", depth: "depth2" },
  {
    title: "오픈소스 라이센스",
    link: "Setting/Policy/License",
    depth: "depth2 last",
  },
];
export const subMember = [
  {
    title: "회원관리",
    link: "/Member/Manage",
    depth: "depth1",
    subLink: ["/Member/Manage"],
  },
  { title: "와플러 관리", link: "/Member/Manage", depth: "depth2 last" },
  { title: "탈퇴 회원관리", link: "/Member/Leave", depth: "depth1 last" },
  {
    title: "WAFL 관리",
    link: "/Member/Wafl/history",
    depth: "depth1",
    subLink: ["/Member/Wafl/history", "/Member/Wafl/ExchangeRate"],
  },
  {
    title: "WAFL 내역",
    link: "/Member/Wafl/history",
    depth: "depth2",
  },
  {
    title: "WAFL 환율 관리",
    link: "/Member/Wafl/ExchangeRate",
    depth: "depth2 last",
  },
];
export const subHotel = [
  { title: "호텔 태그 관리", link: "/Hotel/Tag", depth: "depth1" },
  { title: "해외 지역 관리", link: "/Hotel/Location", depth: "depth1" },
  { title: "호텔 내역", link: "/Hotel/List", depth: "depth1" },
];
export const subProduct = [
  { title: "해외여행 상품관리", link: "/Product/Golf", depth: "depth1" },
  { title: "특가 상품관리", link: "/Product/SpecialPrice", depth: "depth1" },
  { title: "상품 국가 관리", link: "/Product/Package", depth: "depth1" },
  // { title: "상품 국가 관리", link: "/Product/Package", depth: "depth2" },
  // {
  //   title: "패키지 상품 관리",
  //   link: "/Product/Package/Manage",
  //   depth: "depth2",
  // },
];
export const subBoard = [
  { title: "이벤트", link: "/Board/List", depth: "depth1" },
  { title: "매거진", link: "/Board/Magazine", depth: "depth1" },
  { title: "공지사항", link: "/Board/Notice", depth: "depth1" },
  { title: "리뷰 관리", link: "/Board/Review", depth: "depth1" },
  { title: "신고 관리", link: "/Board/Report", depth: "depth1" },
  {
    title: "자주 묻는 질문",
    link: "/Board/TypeManagement",
    depth: "depth1",
    subLink: ["/Board/TypeManagement", "/Board/Faq/List"],
  },
  { title: "유형 관리", link: "/Board/TypeManagement", depth: "depth2" },
  {
    title: "자주 묻는 질문 내역",
    link: "/Board/Faq/List",
    depth: "depth2 last",
  },
];

export const subReserve = [
  {
    title: "호텔 예약 내역",
    link: "/Reserve/Complete",
    depth: "depth1",
    subLink: [
      "/Reserve/Complete",
      "/Reserve/Usage",
      "/Reserve/Refund",
      "/Reserve/CancellationHistory",
    ],
  },
  { title: "예약 내역", link: "/Reserve/Complete", depth: "depth2" },
  { title: "이용 내역", link: "/Reserve/Usage", depth: "depth2" },
  { title: "환불 내역", link: "/Reserve/Refund", depth: "depth2" },
  {
    title: "취소 내역",
    link: "/Reserve/CancellationHistory",
    depth: "depth2 last",
  },
  {
    title: "상품결제내역",
    link: "/Reserve/Payment",
    depth: "depth1",
    subLink: [
      "/Reserve/Payment",
      "/Reserve/SpecialExhibition",
      "/Reserve/ProductPayment",
    ],
  },
  {
    title: "골프 여행 결제 내역",
    link: "/Reserve/Payment",
    depth: "depth2",
  },
  {
    title: "기획전 결제 내역",
    link: "/Reserve/SpecialExhibition",
    depth: "depth2",
  },
  {
    title: "패키지 여행상품 결제 내역",
    link: "/Reserve/ProductPayment",
    depth: "depth2",
  },
];

export const subFeed = [
  {
    title: " 피드 태그 관리",
    link: "/Feed/Management",
    depth: "depth1",
    subLink: ["/Feed/TagRegistration"],
  },
  { title: " 피드 내역", link: "/Feed/History", depth: "depth1" },
  {
    title: "주변 호텔 더보기 반경",
    link: "/Feed/Nearbyhotels",
    depth: "depth1",
  },
];

export const subTrip = [
  { title: "여행 태그 관리", link: "/Trip/Management", depth: "depth1" },
  { title: "추천여행", link: "/Trip/Overseas", depth: "depth1" },
];
