import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  subBoard,
  subFeed,
  subHotel,
  subMember,
  subProduct,
  subReserve,
  subSetting,
  subTrip,
} from "../../utils/Constants/routesContants";

function SideMenu({ setGnbIdx }) {
  const pathName = useLocation().pathname;
  const [isActive, setIsActive] = useState(false);

  let arr = [];
  if (pathName.startsWith("/Login")) {
    return <></>;
  } else if (pathName.startsWith("/Setting")) {
    arr = subSetting;
  } else if (pathName.startsWith("/Member")) {
    arr = subMember;
  } else if (pathName.startsWith("/Hotel")) {
    arr = subHotel;
  } else if (pathName.startsWith("/Board")) {
    arr = subBoard;
  } else if (pathName.startsWith("/Product")) {
    arr = subProduct;
  } else if (pathName.startsWith("/Reserve")) {
    arr = subReserve;
  } else if (pathName.startsWith("/Feed")) {
    arr = subFeed;
  } else if (pathName.startsWith("/Trip")) {
    arr = subTrip;
  } else {
    // arr = 'subSetting';
  }

  return (
    <div className="gnb_sub_wrap">
      <NavLink to="/" className="logo">
        <h1>와플스테이</h1>
      </NavLink>
      <div className="list">
        {arr.map((v, idx) => {
          return (
            <NavLink
              to={v.link}
              key={idx}
              onClick={() => {
                if (v.headerIndex) {
                  setGnbIdx(v.headerIndex);
                }
                setIsActive(false);
              }}
              className={`link ${v.depth} ${
                isActive ||
                v?.subLink?.includes(pathName) ||
                v?.subLink?.reduce(
                  (accu, element) => pathName.includes(element) || accu,
                  false
                )
                  ? "active"
                  : ""
              }`}
            >
              {v.title}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(SideMenu);
