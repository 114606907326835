/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import ArrowRight from "../../assets/img/icon_arrow_right.png";
import ArrowRightEnd from "../../assets/img/icon_arrow_right_end.png";

function Paging({ currentPage = 1, totalPages = 10, onPageChange = () => { } }) {
  const maxPageButtons = 5; // Adjust as needed
  const startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
  const pageButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(
      <button
        key={i}
        onClick={() => onPageChange(i)} // Call the onPageChange callback
        className={`btn_page ${i === currentPage ? "active" : ""}`}
      >
        {i}
      </button>
    );
  }

  return (
    <div className="com_paging">
      <button
        disabled={currentPage <= 1}
        onClick={() => onPageChange(1)}
        className="btn_first"
      >
        <img src={ArrowRightEnd} alt="end" />
      </button>
      <button
        disabled={currentPage <= 1}
        onClick={() => onPageChange(currentPage - 1)}
        className="btn_prev"
      >
        <img src={ArrowRight} alt="prev" />
      </button>
      {pageButtons}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        className="btn_next"
        disabled={totalPages <= currentPage}
      >
        <img src={ArrowRight} alt="next" />
      </button>
      <button
        disabled={totalPages <= currentPage}
        onClick={() => onPageChange(totalPages)}
        className="btn_last"
      >
        <img src={ArrowRightEnd} alt="end" />
      </button>
    </div>
  );
}

export default Paging;
