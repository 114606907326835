import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Header from "./views/component/Header";
import Routers from "./Routers/routers";

function App() {
  return (
    <Router>
      <Header />
      <Suspense fallback={<div>Loading... </div>}>
        <Routers />
      </Suspense>
    </Router>
  );
}

export default App;
