import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FeedManagement from "../views/page/feed/FeedManagement";
import FeedTagRegistration from "../views/page/feed/FeedTagRegistration";
import FeedHistory from "../views/page/feed/FeedHistory";
import FeedHistoryDetails from "../views/page/feed/FeedHistoryDetails";
import FeedNearbyHotels from "../views/page/feed/FeedNearbyHotels";

const RouterFeed = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/Feed") {
      navigate("/Feed/Management");
    }
  }, [location.pathname, navigate]);
  return (
    <>
      <Routes>
        <Route exact path="/Management" element={<FeedManagement />} />
        <Route
          exact
          path="/TagRegistration"
          element={<FeedTagRegistration />}
        />
        <Route exact path="/TagDetails/:id" element={<FeedTagRegistration />} />
        <Route exact path="/History" element={<FeedHistory />} />
        <Route exact path="/History/Details" element={<FeedHistoryDetails />} />
        <Route
          exact
          path="/History/Details/:id"
          element={<FeedHistoryDetails />}
        />
        <Route exact path="/Nearbyhotels" element={<FeedNearbyHotels />} />
      </Routes>
    </>
  );
};

export default RouterFeed;
