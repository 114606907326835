import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "https://waflstay.awsome-app.kr:4005/api";
const apiRequest = async (method, url, config = {}) => {
  try {
    const response = await axios({
      method,
      url: `${BASE_URL}${url}`,
      ...config,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get = async (url, config = {}) => {
  return apiRequest("get", url, config);
};

export const post = async (url, data, config = {}) => {
  return apiRequest("post", url, { data, ...config });
};

export const del = async (url, data = {}, config = {}) => {
  return apiRequest("delete", url, { data, ...config });
};

export const put = async (url, data = {}, config = {}) => {
  return apiRequest("put", url, { data, ...config });
};
